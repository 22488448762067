import React, { useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import i18n from 'i18n-js';
import { useDispatch, useSelector } from 'react-redux';
import {
  ANNOUNCEMENT_SELECTED_AUDIENCE_QUERY,
  ANNOUNCEMENT_SELECTED_AUDIENCE_COUNT_QUERY,
  ADD_RECIPIENTS_TO_ANNOUNCEMENT_MUTATION,
  CONTACTS_QUERY,
  GROUPS_V2_QUERY,
  LABELS_QUERY,
  REMOVE_RECIPIENTS_FROM_ANNOUNCEMENT_MUTATION,
  TAGS_V2_QUERY,
  usePaginated,
} from 'client-lib';
import PropTypes from 'prop-types';
import { getSortOptions } from '../../shared/utils.ts';
import {
  Container,
  customInputStyle,
  Header,
} from '../../createBroadcastList/pages/AudienceBuilder/styles';
import { TabList } from '../../../../elements';
import SearchBar from '../../../SearchBar/SearchBar';
import ContactsTable from '../../shared/AudienceBuilderTables/ContactsTable.tsx';
import GroupsTable from '../../shared/AudienceBuilderTables/GroupsTable';
import SelectedAudienceTable from '../../shared/AudienceBuilderTables/SelectedAudienceTable.tsx';
import SaveAndExit from './subComponents/SaveAndExit';
import GroupMessagingTemplate from '../GroupMessageTemplate';
import { openSnackbar } from '../../../../actions/general';
import ListsTable from './subComponents/ListsTable';
import LabelsTable from '../../shared/AudienceBuilderTables/LabelsTable';
import ToggleAllMessage from './subComponents/ToggleAllMessage';

const TABS = {
  CONTACTS: 'contacts',
  GROUPS: 'groups',
  SELECTED: 'selected',
  BROADCAST_LISTS: 'broadcastLists',
};

const GroupMessageAudienceBuilder = ({
  closeWizard,
  wizardGlobalProps,
  setWizardPage,
  wizardState,
  mutations,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const hasLabelsFF = useSelector(
    (state) => state.accountData.account?.ff_contact_labels
  );

  const { announcementQuery } = wizardGlobalProps;
  const { announcementId } = wizardState;
  const {
    sendStartedAt,
    type,
    targetAllContacts: hasTargetAll,
  } = announcementQuery?.data?.announcement?.announcement ?? {};
  const { updateAnnouncementMutation } = mutations;

  const sortOptions = getSortOptions(i18n);
  const ascending = sortOptions[0];
  const [selectedTab, setSelectedTab] = useState(
    !sendStartedAt ? TABS.CONTACTS : TABS.SELECTED
  );

  // Sort States
  const [contactSort, setContactSort] = useState(ascending);
  const [groupSort, setGroupSort] = useState(ascending);
  const [listSort, setListSort] = useState(ascending);
  const [selectedSort, setSelectedSort] = useState(ascending);
  const [labelsSort, setLabelsSort] = useState(ascending);

  // Filter States
  const [search, setSearch] = useState('');
  const [contactFilters, setContactFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [contactGroupFilters, setContactGroupFilters] = useState([]);
  const [contactLabelFilters, setContactLabelFilters] = useState([]);
  const [audienceGroupFilters, setAudienceGroupFilters] = useState([]);
  const [audienceLabelFilters, setAudienceLabelFilters] = useState([]);
  const [targetAllContacts, setTargetAllContacts] = useState(hasTargetAll);
  const genericErrorMsg = i18n.t('slideouts-GroupMessageName-genericError');

  const customMapNode = (edgeNode) => ({
    ...edgeNode.node,
    selected: edgeNode.selected,
  });

  // Data for Contacts tab
  const {
    contacts: contactsData,
    handleFetchMore: fetchContacts,
    loading: contactsLoading,
    pageInfo: contactPageInfo,
    refetch: refetchContacts,
  } = usePaginated({
    client,
    filter: search,
    query: CONTACTS_QUERY,
    queryVariables: {
      notWithAnnouncementIds: [announcementId],
      groupIds: contactGroupFilters,
      labelIds: contactLabelFilters,
      sortType: contactSort.value,
    },
    resultsNumber: 30,
    key: 'contacts',
  });

  // Data for Groups tab
  const {
    groupsV2: groupsData,
    handleFetchMore: fetchGroups,
    loading: groupsLoading,
    pageInfo: groupPageInfo,
    refetch: refetchGroups,
  } = usePaginated({
    client,
    query: GROUPS_V2_QUERY,
    key: 'groupsV2',
    filter: search,
    queryVariables: {
      notWithAnnouncementIds: [announcementId],
      relayStylePagination: true,
      sortType: groupSort.value,
    },
  });

  // Data for BTM lists
  const {
    handleFetchMore: tagsHandleFetchMore,
    tagsV2: tags,
    loading: tagsLoading,
    refetch: tagsRefetch,
    pageInfo: tagsPageInfo,
  } = usePaginated({
    client,
    query: TAGS_V2_QUERY,
    filter: search,
    queryVariables: {
      notInAnnouncementId: announcementId,
      sortType: listSort.value,
    },
    resultsNumber: 30,
    key: 'tagsV2',
    customMapNode,
  });

  // Data for labels tab
  const {
    handleFetchMore: fetchLabels,
    labels: labelsData,
    loading: labelsLoading,
    pageInfo: labelsPageInfo,
    refetch: refetchLabels,
  } = usePaginated({
    client,
    filter: search,
    key: 'labels',
    query: LABELS_QUERY,
    queryVariables: {
      notWithAnnouncementIds: [announcementId],
      sortType: labelsSort.value,
      relayStylePagination: true,
    },
  });

  // selected audience data
  // Data for Selected Audience counts
  const {
    selectedAudience,
    handleFetchMore: fetchAudience,
    loading: audienceLoading,
    refetch: audienceRefetch,
    pageInfo: audiencePageInfo,
  } = usePaginated({
    client,
    query: ANNOUNCEMENT_SELECTED_AUDIENCE_QUERY,
    key: 'selectedAudience',
    filter: search,
    queryVariables: {
      announcementId,
      sortType: selectedSort.value,
      groupIds: audienceGroupFilters,
      labelIds: audienceLabelFilters,
    },
  });

  // selected audience data
  // Data for Selected Audience counts
  const { data: selectedAudienceCount, refetch: refetchAudienceCount } =
    useQuery(ANNOUNCEMENT_SELECTED_AUDIENCE_COUNT_QUERY, {
      client,
      variables: {
        announcementId,
        sortType: selectedSort.value,
        groupIds: audienceGroupFilters,
        labelIds: audienceLabelFilters,
      },
    });

  // mutations for adding / removing
  const [removeRecipientsFromAnncMutation] = useMutation(
    REMOVE_RECIPIENTS_FROM_ANNOUNCEMENT_MUTATION,
    { client }
  );
  const [addRecipientsToAnncMutation] = useMutation(
    ADD_RECIPIENTS_TO_ANNOUNCEMENT_MUTATION,
    { client }
  );

  // Selects an active tab and unsets any active filters
  const handleTabSelect = ({ value }) => {
    setSelectedTab(value);
    setContactSort(ascending);
    setGroupSort(ascending);
    setSelectedSort(ascending);
    setContactFilters({});
    setSelectedFilters({});
    setContactGroupFilters([]);
    setContactLabelFilters([]);
  };

  const getTabs = (selected = 0) =>
    hasLabelsFF
      ? [
          {
            label: i18n.t('customers-CustomerList-contacts', {
              defaultValue: 'Contacts',
            }),
            value: TABS.CONTACTS,
          },
          {
            label: i18n.t(
              'slideouts-GroupMessageRecipients-broadcastListsLabel',
              {
                defaultValue: 'Broadcast Lists',
              }
            ),
            value: TABS.BROADCAST_LISTS,
          },
          {
            label: i18n.t('slideouts-GroupMessageRecipients-groupLists', {
              defaultValue: 'Groups',
            }),
            value: TABS.GROUPS,
          },
          {
            label: i18n.t('settings-Label-labels', {
              defaultValue: 'Labels',
            }),
            value: TABS.LABELS,
          },
          {
            label: i18n.t('broadcasts-broadcast-selectedAudience', {
              defaultValue: 'Selected Audience (%{number})',
              number:
                selectedAudienceCount?.selectedAudienceCount?.count ||
                selected ||
                0,
            }),
            value: TABS.SELECTED,
          },
        ]
      : [
          {
            label: i18n.t('customers-CustomerList-contacts', {
              defaultValue: 'Contacts',
            }),
            value: TABS.CONTACTS,
          },
          {
            label: i18n.t(
              'slideouts-GroupMessageRecipients-broadcastListsLabel',
              {
                defaultValue: 'Broadcast Lists',
              }
            ),
            value: TABS.BROADCAST_LISTS,
          },
          {
            label: i18n.t('slideouts-GroupMessageRecipients-groupLists', {
              defaultValue: 'Groups',
            }),
            value: TABS.GROUPS,
          },
          {
            label: i18n.t('broadcasts-broadcast-selectedAudience', {
              defaultValue: 'Selected Audience (%{number})',
              number:
                selectedAudienceCount?.selectedAudienceCount?.count ||
                selected ||
                0,
            }),
            value: TABS.SELECTED,
          },
        ];

  const activeTabs = getTabs(selectedAudience?.length).map((tab) => ({
    ...tab,
    active: selectedTab === tab.value,
  }));

  const refetchAll = () => {
    refetchContacts();
    refetchGroups();
    refetchLabels();
    tagsRefetch();
    audienceRefetch();
    refetchAudienceCount();
  };

  /**
   * Handles adding and removing contacts/companies/groups to lists
   * @param {object} selected array of contacts/companies/groups from one of the above tabs
   * @param {bool} remove flag to either add or remove the selected contacts/companies/groups
   */
  const handleRecipientsUpdate = async ({
    companyIds = [],
    contactIds = [],
    groupIds = [],
    labelIds = [],
    tagIds = [],
    remove = false,
  }) => {
    let mutationsHadErrors = false;
    if (
      remove &&
      (companyIds.length ||
        contactIds.length ||
        groupIds.length ||
        labelIds.length ||
        tagIds.length)
    ) {
      const res = await removeRecipientsFromAnncMutation({
        variables: {
          input: {
            announcementId,
            companyIds,
            contactIds,
            groupIds,
            tagIds,
            labelIds,
          },
        },
      }).catch(() => {
        dispatch(openSnackbar(genericErrorMsg, 'error'));
        mutationsHadErrors = true;
      });

      const { errors } = res.data.removeRecipientsFromAnnouncement;
      if (errors) {
        mutationsHadErrors = true;
      }
    } else {
      const res = await addRecipientsToAnncMutation({
        variables: {
          input: {
            announcementId,
            companyIds,
            contactIds,
            groupIds,
            tagIds,
            labelIds,
          },
        },
      }).catch(() => {
        dispatch(openSnackbar(genericErrorMsg, 'error'));
        mutationsHadErrors = true;
        // TODO handle errors stuff
        // i18n.t('nothing', defaultMessage: 'There was an error' })
      });

      const { errors } = res.data.addRecipientsToAnnouncement;
      if (errors) {
        mutationsHadErrors = true;
      }
    }

    if (!mutationsHadErrors) {
      await refetchAll();

      const addedCount =
        companyIds.length +
        contactIds.length +
        groupIds.length +
        labelIds.length +
        tagIds.length;

      if (remove) {
        dispatch(
          openSnackbar(
            i18n.t(
              addedCount === 1
                ? 'broadcast-groupMessageAudience-removedRecipient'
                : 'broadcast-groupMessageAudience-removedRecipients',
              {
                defaultValue:
                  addedCount === 1
                    ? 'Contact removed successfully.'
                    : 'Contacts removed successfully.',
              }
            )
          )
        );
      } else {
        dispatch(
          openSnackbar(
            i18n.t(
              addedCount === 1
                ? 'broadcast-groupMessageAudience-addedRecipient'
                : 'broadcast-groupMessageAudience-addedRecipients',
              {
                defaultValue:
                  addedCount === 1
                    ? 'Contact added successfully.'
                    : 'Contacts added successfully.',
              }
            )
          )
        );
      }
    } else {
      dispatch(openSnackbar(genericErrorMsg, 'error'));
    }
  };

  // Maps selected filter options into an array of IDs
  const handleSubmitFilter = (filters) => {
    const groupIds = filters?.groups?.map((group) => group.value);
    const labelIds = filters?.labels?.map((label) => label.value);
    if (selectedTab === TABS.CONTACTS) {
      setContactGroupFilters(groupIds);
      setContactLabelFilters(labelIds);
      setContactFilters(filters);
    } else if (selectedTab === TABS.SELECTED) {
      setAudienceGroupFilters(groupIds);
      setAudienceLabelFilters(labelIds);
      setSelectedFilters(filters);
    }
  };

  const handleSubmitSort = (sort) => {
    if (selectedTab === TABS.CONTACTS) {
      setContactSort(sort);
    } else if (selectedTab === TABS.GROUPS) {
      setGroupSort(sort);
    } else if (selectedTab === TABS.SELECTED) {
      setSelectedSort(sort);
    } else if (selectedTab === TABS.BROADCAST_LISTS) {
      setListSort(sort);
    } else if (selectedTab === TABS.LABELS) {
      setLabelsSort(sort);
    }
  };

  const updateAnnouncementTargetAllContacts = async (onSuccess) => {
    const onMutationFailure = () => {
      dispatch(openSnackbar(genericErrorMsg, 'error'));
    };

    const { data } = await updateAnnouncementMutation({
      variables: {
        input: {
          id: announcementId,
          targetAllContacts,
        },
      },
    }).catch((e) => {
      console.error(e);
      onMutationFailure();
    });

    if (data?.updateAnnouncement?.errors) {
      onMutationFailure();
    } else {
      onSuccess?.();
    }
  };

  const handleSave = (onSuccess) => {
    const isExecutingMutation = false;

    const onMutationSuccess = () => {
      onSuccess?.();
      refetchAll();
    };

    // if targetAllContacts state is different than what's currently set on the announcement, update it.
    if (targetAllContacts !== hasTargetAll) {
      updateAnnouncementTargetAllContacts(onMutationSuccess).catch((err) => {
        console.error(err);
        dispatch(openSnackbar(genericErrorMsg, 'error'));
      });
    }

    // in case no save is actually necessary, still run the onSuccess stuff.
    if (!isExecutingMutation) {
      onMutationSuccess();
    }
  };

  const justANormalSave = () => {
    handleSave(() =>
      dispatch(
        openSnackbar(i18n.t('slideouts-GroupMessageAudience-successfullySaved'))
      )
    );
  };

  const handleContinue = () => {
    handleSave();
    setWizardPage('GroupMessageOverviewCombined');
  };

  const toggleAllContacts = () => {
    setTargetAllContacts(!targetAllContacts);
  };

  const backStep = () => setWizardPage('GroupMessageOverviewCombined');

  return (
    <GroupMessagingTemplate
      title={i18n.t('broadcasts-broadcast-selectRecipients')}
      headerRightElement={
        <SaveAndExit
          closeWizard={closeWizard}
          onClose={!sendStartedAt ? justANormalSave : null}
          sendStartedAt={!!sendStartedAt}
        />
      }
      showFooter
      continueButtonText={i18n.t(
        'slideouts-GroupMessageRecipients-saveContinue'
      )}
      continueButtonAction={handleContinue}
      hideContinueButton={!!sendStartedAt}
      backButtonAction={backStep}
      bottomFooterProps={{
        secondaryAction: toggleAllContacts,
        secondaryDisabled: sendStartedAt,
        secondaryValue: targetAllContacts,
        secondaryLabel: i18n.t(
          'slideouts-GroupMessageRecipients-targetAllContacts'
        ),
      }}
    >
      {targetAllContacts ? (
        <ToggleAllMessage />
      ) : (
        <Container>
          <Header>
            <TabList
              dataTestId="create-broadcastList-tabs"
              options={activeTabs}
              onClick={handleTabSelect}
            />
            <SearchBar
              dataTestId="create-broadcastList-search"
              value={search}
              setValue={(e) => setSearch(e)}
              placeholder={i18n.t('search-Search-search')}
              hideBottomSpace
              customContainerStyle={customInputStyle}
            />
          </Header>
          {selectedTab === TABS.CONTACTS && (
            <ContactsTable
              contacts={contactsData || []}
              onAdd={handleRecipientsUpdate}
              onFilter={handleSubmitFilter}
              onSort={handleSubmitSort}
              filters={contactFilters}
              sort={contactSort}
              pageInfo={contactPageInfo}
              loading={contactsLoading}
              loadMoreRows={fetchContacts}
              heightDifference={354}
              disableCheckboxes={!!sendStartedAt}
              type={type}
            />
          )}
          {selectedTab === TABS.GROUPS && (
            <GroupsTable
              groups={groupsData || []}
              onAdd={handleRecipientsUpdate}
              onSort={handleSubmitSort}
              sort={groupSort}
              pageInfo={groupPageInfo}
              loading={groupsLoading}
              loadMoreRows={fetchGroups}
              heightDifference={354}
              disableCheckboxes={!!sendStartedAt}
            />
          )}
          {selectedTab === TABS.BROADCAST_LISTS && (
            <ListsTable
              onSort={handleSubmitSort}
              loadMoreRows={tagsHandleFetchMore}
              lists={tags}
              sort={listSort}
              loading={tagsLoading}
              onAdd={handleRecipientsUpdate}
              heightDifference={354}
              disableCheckboxes={!!sendStartedAt}
              pageInfo={tagsPageInfo}
            />
          )}
          {selectedTab === TABS.LABELS && (
            <LabelsTable
              labels={labelsData || []}
              onAdd={handleRecipientsUpdate}
              onSort={handleSubmitSort}
              sort={labelsSort}
              pageInfo={labelsPageInfo}
              loading={labelsLoading}
              loadMoreRows={fetchLabels}
              disableCheckboxes={!!sendStartedAt}
            />
          )}
          {selectedTab === TABS.SELECTED && (
            <SelectedAudienceTable
              selectedAudience={selectedAudience || []}
              onRemove={handleRecipientsUpdate}
              onFilter={handleSubmitFilter}
              onSort={handleSubmitSort}
              filters={selectedFilters}
              sort={selectedSort}
              pageInfo={audiencePageInfo}
              loading={audienceLoading}
              loadMoreRows={fetchAudience}
              heightDifference={354}
              disableCheckboxes={!!sendStartedAt}
            />
          )}
        </Container>
      )}
    </GroupMessagingTemplate>
  );
};

GroupMessageAudienceBuilder.propTypes = {
  closeWizard: PropTypes.func.isRequired,
  setWizardPage: PropTypes.func.isRequired,
  wizardState: PropTypes.object.isRequired,
  wizardGlobalProps: PropTypes.object.isRequired,
  mutations: PropTypes.object.isRequired,
};

export default GroupMessageAudienceBuilder;
