// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./pk-svgs/bard-fill.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pk-bard-fill {
    width:16px;
    height:16px;
    background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    margin:0;
    padding:0;
  }`, "",{"version":3,"sources":["webpack://./src/assets/pk-svg.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,wDAA+C;IAC/C,QAAQ;IACR,SAAS;EACX","sourcesContent":[".pk-bard-fill {\n    width:16px;\n    height:16px;\n    background-image:url('./pk-svgs/bard-fill.svg');\n    margin:0;\n    padding:0;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
