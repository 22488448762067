import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import THEMES from '../../styles/themes/library/chip';
import DEFAULT_THEMES from '../../styles/themes/app';
import IconButton from '../Button/IconButton';
import { EmphasisText } from '../Text/Text';

export enum Sizes {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum HighlightTypes {
  YELLOW = 'yellow',
  GREEN = 'green',
  AI_IDENTIFIED = 'AI',
}

interface ChipProps {
  children: string | number | JSX.Element;
  onDelete?: (() => void) | null;
  size?: Sizes;
  icon?: JSX.Element | null;
  maxWidth?: number;
  highlight?: boolean;
  dataTestId?: string;
  highlightType?: HighlightTypes;
}
interface ChipInternal {
  children: string | number | JSX.Element;
  onDelete?: (() => void) | null;
  size: Sizes;
  icon: JSX.Element | null;
  maxWidth: number;
  highlight: boolean;
  dataTestId: string;
  highlightType: HighlightTypes;
}

interface HighlightProps {
  highlight: boolean;
  highlightType: HighlightTypes;
}

interface Buttons {
  onMouseOver: () => void;
  onMouseOut: () => void;
  className: string;
  onClick: () => void;
  customStyle: (props: ChipInternal) => string;
}

/** LARGE CHIP */
const LARGE_CHIP_FONTSIZE = 'initial';
const DELETE_ICON_HOVER_FONTSIZE_LG = theme('fontSize', {
  default: '22',
  large: '26',
});
const DELETE_ICON_SIZE_HOVER_LG = theme('fontSize', {
  default: '20',
  large: '24',
});
const DELETE_ICON_SIZE_LG = theme('fontSize', {
  default: '16',
  large: '20',
});
const CONTAINER_SIZE_LG = 'auto';

/** MEDIUM CHIP */
const MEDIUM_CHIP_FONTSIZE = theme('fontSize', {
  default: '12px',
  large: '16px',
});
const DELETE_ICON_HOVER_FONTSIZE_MD = theme('fontSize', {
  default: '16',
  large: '20',
});
// ICON SIZE is the _container_ size, not the icon size (controlled by font)
// Bump the container size to allow for proper placement of the icon
// The jumping happens due to using icons that are slightly different
//  rather than -line and -fill of the same icon.
const DELETE_ICON_SIZE_HOVER_MD = theme('fontSize', {
  default: '20',
  large: '24',
});
const DELETE_ICON_SIZE_MD = theme('fontSize', {
  default: '16',
  large: '20',
});
const CONTAINER_SIZE_MD = theme('fontSize', {
  default: '27px',
  large: '31px',
});

/** SMALL CHIP */
const SMALL_CHIP_FONTSIZE = theme('fontSize', {
  default: '11px',
  large: '15px',
});
const DELETE_ICON_HOVER_FONTSIZE_SM = theme('fontSize', {
  default: '22',
  large: '26',
});
const DELETE_ICON_SIZE_HOVER_SM = theme('fontSize', {
  default: '16',
  large: '20',
});
const DELETE_ICON_SIZE_SM = theme('fontSize', {
  default: '12',
  large: '16',
});
const CONTAINER_SIZE_SM = 'auto';

enum styleItems {
  iconSize = 'iconSize',
  iconSizeHover = 'iconSizeHover',
  iconFontSize = 'iconFontSize',
  iconFontSizeHover = 'iconFontSizeHover',
  customTextFontSize = 'customTextFontSize',
  padding = 'padding',
  paddingRight = 'paddingRight',
  containerHeight = 'containerHeight',
  gap = 'gap',
}

const sizeStyles = (props: ChipInternal & { extraPadding?: boolean }) => ({
  [Sizes.sm]: {
    [styleItems.iconSize]: DELETE_ICON_SIZE_SM(props),
    [styleItems.iconSizeHover]: DELETE_ICON_SIZE_HOVER_SM(props),
    [styleItems.iconFontSize]: DELETE_ICON_SIZE_SM(props),
    [styleItems.iconFontSizeHover]: DELETE_ICON_HOVER_FONTSIZE_SM(props),
    [styleItems.customTextFontSize]: SMALL_CHIP_FONTSIZE(props),
    [styleItems.padding]: props.extraPadding ? '2px 4px 2px 8px' : '2px 8px',
    [styleItems.paddingRight]: 4,
    [styleItems.containerHeight]: CONTAINER_SIZE_SM,
    [styleItems.gap]: props.icon ? '4px' : null,
  },
  [Sizes.md]: {
    [styleItems.iconSize]: DELETE_ICON_SIZE_MD(props),
    [styleItems.iconSizeHover]: DELETE_ICON_SIZE_HOVER_MD(props),
    [styleItems.iconFontSize]: DELETE_ICON_SIZE_MD(props),
    [styleItems.iconFontSizeHover]: DELETE_ICON_HOVER_FONTSIZE_MD(props),
    [styleItems.customTextFontSize]: MEDIUM_CHIP_FONTSIZE(props),
    [styleItems.padding]: props.extraPadding ? '4px 4px 4px 8px' : '4px 8px',
    [styleItems.paddingRight]: 4,
    [styleItems.containerHeight]: CONTAINER_SIZE_MD(props),
    [styleItems.gap]: '4px',
  },
  [Sizes.lg]: {
    [styleItems.iconSize]: DELETE_ICON_SIZE_LG(props),
    [styleItems.iconSizeHover]: DELETE_ICON_SIZE_HOVER_LG(props),
    [styleItems.iconFontSize]: DELETE_ICON_SIZE_LG(props),
    [styleItems.iconFontSizeHover]: DELETE_ICON_HOVER_FONTSIZE_LG(props),
    [styleItems.customTextFontSize]: LARGE_CHIP_FONTSIZE,
    [styleItems.padding]: props.extraPadding ? '4px 4px 4px 8px' : '4px 8px',
    [styleItems.paddingRight]: 8,
    [styleItems.containerHeight]: CONTAINER_SIZE_LG,
    [styleItems.iconButtonSize]: 'md',
    [styleItems.gap]: props.icon ? '4px' : null,
  },
});

const getStyle = (item: styleItems, props: ChipInternal) =>
  sizeStyles(props)[props.size][item];

const isHighlightType = (
  props: ChipInternal | HighlightProps,
  type: HighlightTypes
) => props.highlight && props.highlightType === type;

const getTextColor = (props: ChipInternal) => {
  if (isHighlightType(props, HighlightTypes.YELLOW)) {
    return THEMES.HIGHLIGHTED_TEXT(props);
  }
  if (isHighlightType(props, HighlightTypes.GREEN)) {
    return THEMES.HIGHLIGHTED_TEXT(props);
  }
  // if (isHighlightType(props, HighlightColors.AI_IDENTIFIED))
  //   return THEMES.HIGHLIGHTED_TEXT(props);
  return THEMES.TEXT(props);
};

const customDeleteButtonStyle = (props: ChipInternal) => `
  display: flex;
  align-items: center;
  height: ${getStyle(styleItems.iconSize, props)}px !important;
  width: ${getStyle(styleItems.iconSize, props)}px;
  && i {
    color: ${getTextColor(props)} !important;
  }
`;

const DeleteIcon = styled.i<ChipInternal & Buttons>`
  && {
    font-size: ${(props) => getStyle(styleItems.iconFontSize, props)}px;
    width: ${(props) => getStyle(styleItems.iconSize, props)}px;
    height: ${(props) => getStyle(styleItems.iconSize, props)}px;
    line-height: ${(props) => getStyle(styleItems.iconSize, props)}px;
    color: ${(props) => getTextColor(props)};

    &:hover {
      font-size: ${(props) => getStyle(styleItems.iconFontSizeHover, props)}px;
      height: ${(props) => getStyle(styleItems.iconSizeHover, props)}px;
      width: ${(props) => getStyle(styleItems.iconSizeHover, props)}px;
      padding-top: 6px;
    }
  }
`;

const ChipEncapsulator = styled.div<ChipInternal & { extraPadding: boolean }>`
  display: flex;
  align-items: center;
  max-height: ${(props) => getStyle(styleItems.containerHeight, props)};
  background-color: ${(props) => {
    if (isHighlightType(props, HighlightTypes.YELLOW))
      return DEFAULT_THEMES.THEME_YELLOW(props);
    if (isHighlightType(props, HighlightTypes.GREEN))
      return THEMES.REGION_BACKGROUND(props);
    if (isHighlightType(props, HighlightTypes.AI_IDENTIFIED))
      return THEMES.AI_BACKGROUND(props);
    return THEMES.BACKGROUND(props);
  }};
  border: ${(props) =>
    isHighlightType(props, HighlightTypes.AI_IDENTIFIED)
      ? `1px solid ${THEMES.AI_BORDER(props)}`
      : ''};
  padding: ${(props) => getStyle(styleItems.padding, props)};
  border-radius: 4px;
`;

const TextWrap = styled.div<ChipInternal & { paddingRight: boolean }>`
  margin-right: ${(props) => getStyle(styleItems.paddingRight, props)}px;
  display: flex;
  align-items: center;
  min-height: ${(props) => getStyle(styleItems.iconSize, props)}px;
  gap: ${(props) => getStyle(styleItems.gap, props)};
  && i {
    color: ${(props) =>
      isHighlightType(props, HighlightTypes.YELLOW)
        ? THEMES.HIGHLIGHTED_TEXT(props)
        : DEFAULT_THEMES.FOREGROUND_HIGH(props)};
  }
`;

const calcMaxWidth = (props: ChipInternal & { paddingRight?: boolean }) => {
  const deleteIconWidth = Number(getStyle(styleItems.iconSize, props));
  // Return sum of padingRight for text element plus paddingRight and paddingLeft (always 8) of Chip element
  const padding =
    Number(getStyle(styleItems.paddingRight, props)) +
    (props.paddingRight ? 4 : 8) +
    8;
  return props.maxWidth - (props.paddingRight ? deleteIconWidth : 0) - padding;
};

const textCustomStyle = (props: ChipInternal) =>
  `
  font-size: ${getStyle(styleItems.customTextFontSize, props)};
  font-weight: 600;
  color: ${getTextColor(props)} !important;
  ${
    props?.maxWidth &&
    `
    overflow: hidden;
    max-width: ${calcMaxWidth(props)}px;
    white-space: nowrap;
    text-overflow: ellipsis;
    `
  }
`;

const AIIcon = () => (
  <i data-testid="AI_Identified_Icon" className="pk-bard-fill" />
);

/**
 * Gets the icon to display.  If an icon is passed in, it will be used.
 * If not, and AI Highlight is enabled, the AI icon will be used.
 * Otherwise, no icon will be displayed.
 * @param props ChipInternal
 * @returns Icon to display
 */
const getIconToDisplay = (
  props: HighlightProps & { icon: JSX.Element | null }
) => {
  if (props.icon) return props.icon;
  if (isHighlightType(props, HighlightTypes.AI_IDENTIFIED)) {
    return <AIIcon />;
  }
  return null;
};

const Chip = ({
  children,
  onDelete = null,
  size = Sizes.lg,
  icon = null,
  maxWidth = 250,
  highlight = false,
  highlightType = HighlightTypes.YELLOW,
  dataTestId = 'chip',
}: ChipProps) => {
  const [deleteIconHover, setDeleteIconHover] = useState(false);
  const displayX = !!onDelete;

  const displayIcon = getIconToDisplay({ icon, highlight, highlightType });

  return (
    // @ts-expect-error next line ;; need to convert theme to TS
    <ChipEncapsulator
      size={size}
      extraPadding={!!onDelete}
      highlight={highlight}
      data-testid={dataTestId}
      highlightType={highlightType}
    >
      {/* @ts-expect-error next line ;; need to convert theme to TS */}
      <TextWrap
        icon={displayIcon}
        paddingRight={displayX}
        highlightType={highlightType}
        size={size}
        highlight={highlight}
      >
        {displayIcon}
        <EmphasisText
          contrast="med"
          paddingRight={displayX}
          size={size}
          maxWidth={maxWidth}
          highlight={highlight}
          highlightType={highlightType}
          customStyle={textCustomStyle}
          dataTestId={`${dataTestId}-EmphasisText`}
        >
          {children}
        </EmphasisText>
      </TextWrap>
      {displayX && (
        // @ts-expect-error next line ;; need to convert theme to TS
        <IconButton
          size={size}
          onClick={onDelete}
          customStyle={(iconButtonProps: ChipInternal) =>
            customDeleteButtonStyle({
              ...iconButtonProps,
              highlight,
              highlightType,
            })
          }
        >
          {/* @ts-expect-error next line ;; need to convert theme to TS */}
          <DeleteIcon
            onMouseOver={() => setDeleteIconHover(true)}
            onMouseOut={() => setDeleteIconHover(false)}
            className={
              deleteIconHover ? 'ri-close-circle-fill' : 'ri-close-fill'
            }
            size={size}
          />
        </IconButton>
      )}
    </ChipEncapsulator>
  );
};

export default Chip;
